<template>
  <div style="margin-left: 2rem;">
    <h4 class="mb-1" style="font-size: 20px; font-weight:600; margin-bottom: 1.3rem;">
      Score Card
    </h4>
    <b-card class="text-center">
      <vue-apex-charts ref="scoreCard" type="polarArea" height="150" :options="scoreChartOptions" :series="series" />
      <span class="font-weight-bold">Average Score : {{ getAvgscore() }}</span>
    </b-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { $themeColors, $chartColors } from "@themeConfig";
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardHeader, VBPopover
} from "bootstrap-vue";
const randomNo = () => {
   let x =  (Math.random() * 100);
   return x.toFixed(0)
}
export default {
  components: {
    VueApexCharts,
    BCard
  },
  methods:{
    getAvgscore(){
      let x = 0;
      this.series.forEach((val) =>x = x + parseInt(val))
      return (x / 500 * 10).toFixed(1)
    }
  },
  data() {
    return {
      series: [randomNo(), randomNo(), randomNo(), randomNo(), randomNo()],
      scoreChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: ['Communication', 'Technical', 'Aptitute', 'Reasoning', 'Leadership'],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        stroke: { width: 0 },
        colors: $chartColors,
        tooltip: {
          fillSeriesColor: false,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        noData: {
          text: "No Data found",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#043c57",
            fontSize: "14px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
        },
      },
    };
  }
}
</script>
<style lang="scss">
  .apexcharts-canvas .apexcharts-tooltip {
	background: rgba(30, 30, 30, 0.8);
	font-weight: 600;
	font-family: "Mulish", sans-serif;

	span {
		font-weight: 800;
		color: #fff;
	}
}
</style>