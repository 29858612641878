<template>
	<div style="margin-left: 2rem;">
		<h4 class="mb-1" style="font-size: 20px; font-weight:600; margin-bottom: 1.3rem;">
			Similar Profiles
		</h4>
		<b-card class="mt-25 mb-75 cursor-pointer" v-for="(data, index) in similarProfileList" :key="index" @click="sendValue(data.id)">
			<div class="d-flex justify-content-start align-items-center">
				<div class="mr-1">
					<b-avatar size="40" style="border-radius: 16px !important;" :src="data.photo"
						:text="avatarText(data.name)" variant="transparent"
						class=" badge-minimal avatar-border text-dark cursor-pointer" @click="sendValue(data.id)" />
				</div>
				<div class="profile-user-info">
					<h6 class="mb-0 cursor-pointer student-name" @click="sendValue(data.id)" style="font-weight:600">
						{{ data.name }}
					</h6>
					<small class="">{{ data.course }}</small>
				</div>
				<!-- @click="$router.push({
                name: 'student-profile',
                params: { studentId: data.id },
              })" -->
				<div class="profile-star cursor-pointer ml-auto" @click="sendValue(data.id)">
					<u-icon icon="fi-br-angle-right" size="12" />
				</div>
			</div>
		</b-card>
	</div>
</template>
<script>
import { BCard, BRow, BCol, BButton, BAvatar, BLink } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
	components: {
		BCard,
		BAvatar,
	},
	props: {
		similarProfileList: {
			type: Array
		}
	},
	data() {
		return {
			pagesData: {},
		};
	},
	methods: {
		avatarText,
		sendValue(id) {
			console.log(id)
			this.$emit('update:clickedStudentId', id)
		}
	},
	created() {

	},
};
</script>
<style lang="scss">
.student-name {
	&:hover {
		color: #039be5 !important;
		transition: all 0.25s ease;

	}
}
</style>
