<template>
  <div>
    <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="pdfFilename"
      :paginationOfElements="true"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @startPagination="startPagination"
      @progress="onProgress($event)"
      @hasGenerated="hasGenerated($event)"
      @beforeDownload="beforeDownload($event)"
      ref="htmlPdf"
    >
      <!-- :html-to-pdf-options="htmlToPdfOptions" -->

      <section
        slot="pdf-content"
        class="mt-0 px-5"
        id="element-to-print"
        style="color: #1d1d1d !important"
      >
        <section class="pdf-item">
          <div class="basic-header mb-3 w-100">
            <div class="match-height d-flex justify-content-between">
              <div>
                <b-media no-body>
                  <!-- <b-media-aside class="mr-1" v-if="studentData.profilephoto != ''">
                    <b-avatar
                      class="badge-minimal avatar-border-2 text-primary"
                      size="90"
                      :src="studentData.profilephoto"
                      variant="transparent"
                      style="border-radius: 52px !important"
                    />
                  </b-media-aside> -->
                  <b-media-body>
                    <h4 class="" style="font-weight: 700">
                      {{ studentData.FullName }}
                    </h4>
                    <h6 class="mt-50 mb-0" style="font-size: 16px; font-weight: 500">
                      {{ studentData.CollegeName }}
                    </h6>
                    <span class="mt-25" style="font-size: 16px; font-weight: 500">{{
                      studentData.courseName
                    }}</span>
                  </b-media-body>
                </b-media>
              </div>
              <div class="text-right pr-50">
                <span class="d-block" style="font-size: 16px; font-weight: 600"
                  >+91-{{ studentData["ContactNo."] }}</span
                >
                <span class="mt-25 d-block" style="font-size: 16px; font-weight: 600">{{
                  studentData.email
                }}</span>
                <span class="mt-25 d-block" style="font-size: 16px; font-weight: 600">{{
                  studentData.DOB
                }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="pdf-item">
          <div class="mb-3">
            <div class="w-100 full-page-bg-color py-75 px-2">
              <h6 class="text-uppercase font-weight-bold mb-0">Education</h6>
            </div>
            <div class="px-2">
              <table class="mt-1">
                <thead>
                  <tr>
                    <th><span style="font-weight: 700">Degree/Certificate</span></th>
                    <th><span style="font-weight: 700">Institute/Board</span></th>
                    <th><span style="font-weight: 700">Percentage/CGPA</span></th>
                    <th><span style="font-weight: 700">Year</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in educationData" :key="item.title">
                    <td>{{ item.title }}</td>
                    <td>{{ item.desc }}</td>
                    <td>{{ item.score }}</td>
                    <td>{{ item.time }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section class="pdf-item">
          <div class="mb-3" v-if="experienceData.length > 0">
            <div class="w-100 full-page-bg-color py-75 px-2">
              <h6 class="text-uppercase font-weight-bold mb-0">Experience</h6>
            </div>
            <div class="mt-1 px-2" v-for="item in experienceData" :key="item.title">
              <div class="d-flex justify-content-between mb-0">
                <span class="d-block mb-0" style="font-weight: 700"
                  >{{ item.jobtitle }} <span> | {{ item.type }}</span></span
                >
                <span class="d-block font-italic">[{{ item.time }}]</span>
              </div>
              <div class="">
                <span class="d-block mb-50">{{ item.company }}</span>
                <span class="d-block">{{ item.desc }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="pdf-item">
          <div class="mb-3" v-if="accompData.length > 0">
            <div class="w-100 full-page-bg-color py-75 px-2">
              <h6 class="text-uppercase font-weight-bold mb-0">Certifications</h6>
            </div>
            <div class="mt-1 px-2" v-for="item in accompData" :key="item.title">
              <div class="d-flex justify-content-between mb-0">
                <span class="d-block mb-0" style="font-weight: 700">{{
                  item.title
                }}</span>
                <span v-if="item.time" class="d-block font-italic"
                  >[{{ item.time }}]</span
                >
              </div>
              <div class="">
                <span class="d-block">{{ item.desc }}</span>
              </div>
            </div>
          </div>
        </section>
        <section class="pdf-item">
          <div class="w-full" v-if="Skills.length > 0">
            <div class="w-100 full-page-bg-color py-75 px-2">
              <h6 class="text-uppercase font-weight-bold mb-0">Skills</h6>
            </div>
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <table class="skillTable">
                <tr v-for="(item, index) in Skills" :key="index">
                  <ul class="mb-0">
                    <li class="mb-0">
                      <span class="ml-2">{{ item }}</span>
                    </li>
                  </ul>
                </tr>
              </table>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
    <!-- <vs-list class="d-flex" v-for="(item, index) in Skills" :key="index">
              <vs-list-item
                icon-pack="feather"
                icon="icon-chevrons-right"
                :title="item"
                class="px-1"
              ></vs-list-item>
            </vs-list> -->
    <!-- </div> -->
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import html2pdf from "html2pdf.js";
import EducationData from "./EducationData.vue";
import TheFooter from "./ResumeFooter.vue";
import { BRow, BCol, BAvatar, BMediaBody, BMedia, BMediaAside } from "bootstrap-vue";
export default {
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    experienceData: {
      type: Array,
      required: true,
    },
    educationData: {
      type: Array,
      required: true,
    },
    accompData: {
      type: Array,
      required: true,
    },
    Skills: {
      type: Array,
      required: true,
    },
  },
  watch: {
    studentData() {
      if (this.studentData) this.pdfFilename = `${this.studentData.FullName}.pdf`;
    },
  },
  computed: {
    htmlToPdfOptions() {
      var filename = `${this.studentData.FullName}.pdf`;
      return {
        margin: [0.3, 0.3, 0.1, 0.3],
        // filename: filename,

        // image: {
        //   type: "png",
        //   quality: 0.98,
        // },
        // enableLinks: true,
        // html2canvas: {
        //   heright: "auto",
        //   scale: 1,
        //   useCORS: true,
        //   scrollY: 0,
        //   scrollX: 0,
        //   y: 0,
        //   // windowHeight: auto,
        // },
        // jsPDF: {
        //   unit: "in",
        //   format: "a4",
        //   orientation: "portrait",
        // },
      };
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    hasGenerated(event) {
      // console.log(event);
    },
    handleScroll() {
      // this.$store.commit("UPDATE_WINDOW_SCROLL_Y", 0);
    },
    async generateReport() {
      // console.log(window.innerHeight);
      // console.log(this.$refs);
      await this.$refs.htmlPdf.generatePdf();
    },
    onProgress(progress) {
      this.progress = progress;
      // console.log(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "COPYRIGHT @ " +
                new Date().getFullYear() +
                " Flairboat Pvt. Ltd., All rights Reserved",
              pdf.internal.pageSize.getWidth() * 0.3,
              pdf.internal.pageSize.getHeight() - 0.1
            );
          }
        })
        .save();
    },
  },

  components: {
    VueHtml2pdf,
    EducationData,
    TheFooter,
    BRow,
    BCol,
    BAvatar,
    BMediaBody,
    BMedia,
    BMediaAside,
  },

  data() {
    return {
      year: "",
      pdfFilename: `${this.studentData.FullName}.pdf`,
    };
  },
  mounted() {
    // this.$emit("setAppClasses", "resume-page");
  },
};
</script>
<style lang="scss">
#element-to-print {
  padding: 3rem 3rem 3rem 3rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: #1b1b1b;
  }
  .skillTable {
    border: none;
    tr {
      width: 50%;
      float: left;
    }
    th,
    td {
      border: none;
      border-collapse: collapse;
    }
    td:nth-child(3) {
      text-align: start;
    }
    td:nth-child(4) {
      text-align: start;
    }
  }
  table,
  th,
  td {
    border: 1px solid #efefef;
    border-collapse: collapse;
  }
  td,
  th {
    padding-left: 10px;
  }
  table {
    // max-width: calc(100% - 30px);
    td:nth-child(3) {
      text-align: center;
    }
    td:nth-child(4) {
      text-align: center;
    }
  }
}
@page {
  /* Footer frame starts outside margin-bottom of @page */
  @frame footer {
    -pdf-frame-content: print-footer;
    bottom: 2cm;
    margin-left: 1cm;
    margin-right: 1cm;
    height: 1cm;
  }
  table {
    // max-width: calc(100% - 30px);
  }
}
.full-page-bg-color {
  background-color: #eff2f7;
}
@media print {
  .resume-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #resume-page,
    #resume-page * {
      visibility: visible;
    }
    #resume-page {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
@page {
  size: auto;
}
</style>
