<template>
	<div style="border-bottom: 1px solid #efefef; margin-right: 2rem; margin-top: 0px !important;" class="mb-2 ">
		<b-row class="match-height">
			<b-col xl="8" md="6" sm="12">
				<b-media no-body>
					<b-media-aside class="mr-1">
						<b-avatar class=" badge-minimal avatar-border-2 text-primary" size="120"
							:src="data.profilephoto" :text="avatarText(data.FullName)" variant="transparent"
							style="border-radius: 52px !important;" />
					</b-media-aside>
					<b-media-body>
						<h2 class=" font-weight-bold">
							{{ data.FullName }}
						</h2>
						<h4 class="mt-50" style="font-size: 20px; font-weight:600">
							{{ data.CollegeName }}
						</h4>
						<span class="mt-25 " style="font-size: 16px;font-weight:600">{{ data.courseName }}</span>
					</b-media-body>
				</b-media>
			</b-col>
			<b-col xl="4" md="6" sm="12">
				<div class="d-flex justify-content-md-end justify-content-sm-center action-buttons">
					<b-button variant="primary" class="mr-1 btn-icon" size="sm"
						:to="$acl.get == 'admin' ? { name: 'apps-chat', params: { studentId: data.uid } } : { name: 'apps-chat-recruiter', params: { studentId: data.uid } }">
						<u-icon icon="fi-rr-comment-alt" size="16" />
					</b-button>
					<b-button variant="primary" class="mr-1 btn-icon" size="sm" @click="$emit('initiateCall')">
						<u-icon icon="fi-rr-phone-call" size="16" />
					</b-button>
					<b-button variant="primary" class="mr-1 btn-icon" size="sm" @click="$emit('initiateCall')">
						<u-icon icon="fi-rr-video-camera" size="16" />
					</b-button>
				</div>
			</b-col>
		</b-row>
		<div v-if="data" style="margin-left: 134px; margin-top:16px">
			<table class="mt-2 mt-xl-0 w-100 variety-table">
				<tr>
					<td class="pb-1">
						<div class="d-flex align-items-start ">
							<!-- <i class='bx bx-male-sign' v-if="data.gender == 'Male'"></i> -->
							<u-icon v-if="data.gender == 'Female'" icon="fi-rr-venus" class="mr-1" size="16" />
							<u-icon v-else icon="fi-rr-mars" class="mr-1" size="16" />

							<span>{{ data.gender }}</span>
						</div>
					</td>
					<td class="pb-1">
						<div class="d-flex align-items-start ">
							<u-icon icon="fi-rr-smartphone" class="mr-1" size="16" />
							<span>{{ data["ContactNo."] }}</span>
						</div>
					</td>
				</tr>
				<tr>
					<td class="pb-1">
						<div class="d-flex align-items-start ">
							<u-icon icon="fi-rr-cake-birthday" class="mr-1" size="16" />
							<span>{{ data.DOB }}</span>
						</div>
					</td>
					<td class="pb-1 ">
						<div class="d-flex align-items-start ">
							<u-icon icon="fi-rr-envelope" class="mr-1" size="16" />
							<span class="">{{ data.email }}</span>
						</div>
					</td>
				</tr>
			</table>

			<div class="d-flex mt-2 mb-2 justify-content-center" style="align-items: baseline;"
				v-if="$acl.get == 'admin'">
				<b-button variant="primary" class="mr-2 " block style="height:40px"
					v-if="typeof data.verified != 'undefined' ? !data.verified : true"
					@click="verify(data.uid, data.FullName)">
					<span class="text-nowrap font-weight-bold">Verify</span>
				</b-button>
				<b-button variant="outline-danger" class="mr-2 " block style="height:40px" v-else
					@click="unverify(data.uid, data.FullName)">
					<span class="text-nowrap font-weight-bold">Unverify</span>
				</b-button>
				<b-button variant="outline-primary" block @click.prevent="$emit('exportPDF')">
					<b-spinner v-if="exporting" small />

					<span class="text-nowrap font-weight-bold">Download Resume</span>
				</b-button>
			</div>
			<div class="d-flex mt-2 mb-2 justify-content-center" style="align-items: baseline;"
				v-if="$acl.get == 'recruiter'">

				<b-button variant="outline-primary" block @click.prevent="$emit('exportPDF')">
					<b-spinner v-if="exporting" small />
					<span class="text-nowrap font-weight-bold">Download Resume</span>
				</b-button>
			</div>
		</div>
	</div>
</template>
<script>
import {
	BRow,
	BCol,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BMedia,
	BButton,
	BSpinner,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
	components: {
		BRow,
		BCol,
		BMediaBody,
		BMedia,
		BMediaAside,
		BAvatar,
		BButton,
		BSpinner,
	},
	props: {
		data: {
			type: Object,
		},
		exporting: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isVerified: false
		}
	},
	watch: {
		data() {
			// this.isVerified = data.verified
		}
	},
	methods: {
		initiatePhoneCall() {
			let routeData = this.$router.resolve({ name: 'interview-room' });
			// , query: {data: "someData"}
			window.open(routeData.href, '_blank');
		},
		avatarText,
		verify(uid, name) {
			this.$swal({
				title: `Verify ${name}`,
				text: "Are you sure, you want to verify this student profile? ",
				icon: "primary",
				showCancelButton: true,
				confirmButtonText: "Yes, verify it!",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					console.log(uid);
					firebase
						.firestore()
						.collection("StudentID")
						.doc(uid)
						.set(
							{
								verified: true,
							},
							{ merge: true }
						)
						.then((val) => {
							// this.refetchData("firebase")
							// this.isValueUpdated = true;
							console.log("done");
						});
					this.$swal({
						icon: "success",
						title: "Verified!",
						text: `${name} has been verified.`,
						customClass: {
							confirmButton: "btn btn-success",
						},
					});
				}
			});
		},
		unverify(uid, name) {
			this.$swal({
				title: `Unverify ${name}`,
				text: "Do You want to remove verification of this profile ?",
				icon: "danger",
				showCancelButton: true,
				confirmButtonText: "Yes, unverify it!",
				customClass: {
					confirmButton: "btn btn-danger",
					cancelButton: "btn btn-outline-secondary ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					console.log(uid);
					firebase
						.firestore()
						.collection("StudentID")
						.doc(uid)
						.set(
							{
								verified: false,
							},
							{ merge: true }
						)
						.then((val) => {
							// this.refetchData("firebase")
							// this.isValueUpdated = true;
							this.$swal({
								icon: "success",
								title: "Unverified!",
								text: `${name} has been unverified.`,
								customClass: {
									confirmButton: "btn btn-success",
								},
							});
						});
				}
			});
		},
	},
};
</script>
<style lang="scss">
.variety-table {
	i {
		line-height: 1.45;
		margin-top: 1px;
	}
}

.action-buttons {
	.btn {
		background: #e6f5fc !important;
		border-radius: 8px;
		padding: 0.6rem !important;


		i {
			color: #039be5;
		}
	}
}
</style>
