<template>
	<div>
		<generate-resume-profile :studentData="studentData" :educationData="educationdata"
			:experienceData="experiencedata" :Skills="skills" :accompData="accompData" class="hidden" ref="document" />
		<section id="student-profile">
			<b-row class="match-height">
				<b-col xl="8" md="6" sm="12" style="border-right: 1px solid #efefef;">
					<vue-perfect-scrollbar class="profile-scroller" ref="refStudentPS"
						:settings="perfectScrollbarSettings" style=" overflow-x:hidden;">
						<basic-profile :data="studentData" :exporting="exporting" @exportPDF="exportpdf"
							@initiateCall="setOutgoingCall">
						</basic-profile>
						<all-details-component label="Education" :dataArray="educationdata"></all-details-component>
						<all-details-component label="Experience" v-if="experiencedata.length > 0"
							:dataArray="experiencedata"></all-details-component>
						<skills v-if="typeof (skills) != 'undefined' && skills.length > 0" :skills-list="skills">
						</skills>
						<all-details-component v-if="accompData.length > 0" label="Accomplishments"
							:dataArray="accompData"></all-details-component>
						<applied-jobs v-if="$acl.get == 'admin'" :applied-jobs-array="studentData.AppliedIn">
						</applied-jobs>
					</vue-perfect-scrollbar>
				</b-col>
				<b-col xl="4" md="6" sm="12" class="pl-0">
					<score-card class=""></score-card>
					<similar-profiles :clickedStudentId.sync="studentId" :similar-profile-list="similarProfileList">
					</similar-profiles>
				</b-col>
			</b-row>
		</section>
	</div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import BasicProfile from "./components/BasicProfile.vue";
import SimilarProfiles from "./components/SimilarProfileCard.vue";
import AllDetailsComponent from "./components/AllDetailsComponent.vue";
import AppliedJobs from "./components/AppliedJobs.vue";
import Skills from "./components/SkillsDetails.vue";
import ScoreCard from "./components/ScoreCard.vue"
import GenerateResumeProfile from "./components/GenerateResumeProfile/DownloadResume.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { useWindowScroll } from '@vueuse/core'
import * as MeetingAPi from "@/views/apps/videoCall/lib/meeting-api";
import Meeting from "@/views/apps/videoCall/lib/meeting/meeting.js";

export default {
	components: {
		BRow,
		BCol,
		BasicProfile,
		SimilarProfiles,
		AllDetailsComponent,
		AppliedJobs,
		Skills,
		ScoreCard,
		GenerateResumeProfile,
		VuePerfectScrollbar,
	},
	watch: {
		studentData() {
			if (this.studentData.courseName) {
				this.fetchSimilarProfilebyCourse()
			}
		},
		studentId() {
			if (this.studentId) {
				this.fetchStudentData();
				const rootEle = document.documentElement
				rootEle.scrollTo({
					top: 0,
					behavior: 'smooth',
				})
			}
		},
		jobId() {
			if (this.jobId) {
				this.fetchSimilarProfile();
			}
		}
	},
	methods: {
		async setOutgoingCall() {
			var role = this.$acl.get[0]
			console.log('setting outgoing call')
			if (role == 'recruiter' && this.jobId) {
				let jobData = {}
				let uid = firebase.auth().currentUser.uid;
				let userData = JSON.parse(localStorage.getItem("userInfo"))
				var username = userData.username || userData.displayName;
				var meetingId;
				//first get job details
				firebase.firestore().collection('RecruiterJobs').doc(this.jobId).get().then((job) => {
					jobData = job.data()
					jobData.id = job.id
				}).then(async () => {
					//set student data
					const studentPayload = {
						s_uid: this.studentId,
						displayPic: this.studentData.profilephoto,
						s_name: this.studentData.FullName,
					};
					this.$store.dispatch("interview/setStudentData", studentPayload);
					const meetingData = await MeetingAPi.startInterview(username);
					meetingId = meetingData.meetingId
					localStorage.setItem("meetingId", meetingId);
					firebase
						.firestore()
						.collection("InterviewRooms")
						.doc(meetingId)
						.set({
							hrID: uid,
							studentID: this.studentId,
							jobId: jobData.id,
							JobTitle: jobData.JobTitle,
							callerName: username,
							callerPhoto: userData.avatar || userData.profilephoto,
							CompanyName: jobData.CompanyName,
							type: 'audio',
							timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						})
						.then(async () => {
							console.log("meeting saved");
							var payload = {
								name: username,
								meetingId: meetingId,
							};
							// this.$store.dispatch("interview/start", payload);
							// const data = await MeetingAPi.joinInterview(meetingId);
							// // console.log(data);
							// localStorage.setItem("meeting", JSON.stringify(data));
							try {
								const stream = await navigator.mediaDevices.getUserMedia({
									video: true,
									audio: true,
								});
								var meetingDetails = new Meeting({
									meetingId: meetingId,
									stream: stream,
									name: username,
									userId: uid,
								});
								// let routeData = this.$router.resolve({ name: 'interview-room' , params: { meetingDetails: meetingDetails ,studentId:this.studentId} });
								this.$router.push({ name: 'interview-room', params: { meetingDetails: meetingDetails, studentId: this.studentId, jobId: this.jobId } });

								// , query: {data: "someData"}
								// window.open(routeData.href, '_blank');
							} catch (err) {
								console.log(err)
							}
						});
				})
			} else if (role == 'admin') {
				let jobData = {}
				let uid = firebase.auth().currentUser.uid;
				let userData = JSON.parse(localStorage.getItem("userInfo"))
				var username = userData.username || userData.displayName;
				var meetingId;
				const studentPayload = {
					s_uid: this.studentId,
					displayPic: this.studentData.profilephoto,
					s_name: this.studentData.FullName,
				};
				this.$store.dispatch("interview/setStudentData", studentPayload);
				const meetingData = await MeetingAPi.startInterview(username);
				meetingId = meetingData.meetingId
				localStorage.setItem("meetingId", meetingId);
				firebase
					.firestore()
					.collection("InterviewRooms")
					.doc(meetingId)
					.set({
						hrID: uid,
						studentID: this.studentId,
						jobId: 'TPO',
						JobTitle: 'TPO Calling',
						callerName: username,
						callerPhoto: userData.avatar || userData.profilephoto,
						CompanyName: this.$store.state.mainUserCodes.CollegeName,
						type: 'audio',
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					})
					.then(async () => {
						console.log("meeting saved");
						var payload = {
							name: username,
							meetingId: meetingId,
						};
						// this.$store.dispatch("interview/start", payload);
						// const data = await MeetingAPi.joinInterview(meetingId);
						// // console.log(data);
						// localStorage.setItem("meeting", JSON.stringify(data));
						try {
							const stream = await navigator.mediaDevices.getUserMedia({
								video: true,
								audio: true,
							});
							var meetingDetails = new Meeting({
								meetingId: meetingId,
								stream: stream,
								name: username,
								userId: uid,
							});
							// let routeData = this.$router.resolve({ name: 'interview-room' , params: { meetingDetails: meetingDetails ,studentId:this.studentId} });
							this.$router.push({ name: 'calling-room', params: { meetingDetails: meetingDetails, studentId: this.studentId } });

							// , query: {data: "someData"}
							// window.open(routeData.href, '_blank');
						} catch (err) {
							console.log(err)
						}
					});

			}
		},
		fetchSimilarProfile() {
			this.similarProfileList = []
			var studentCode = this.$store.state.mainUserCodes.StudentCode
			firebase.firestore().collection('RecruiterJobs').doc(this.jobId).collection('AppliedCandidates')
				.where("StudentCode", "==", studentCode).limit(3).get().then((students) => {
					students.forEach((stud) => {
						var uid = stud.data().uid
						if (uid != this.studentId) {
							firebase.firestore().collection("StudentID").doc(uid).get().then((doc) => {
								var obj = {}
								obj.id = doc.id
								obj.name = doc.data().FullName
								obj.photo = doc.data().profilephoto
								obj.course = doc.data().courseName
								obj.jobId = this.jobId
								this.similarProfileList.push(obj)
							})
						}
					})
				})
		},
		fetchSimilarProfilebyCourse() {
			if (this.studentData.courseName) {

				console.log('we have course')
				var studentCode = this.$store.state.mainUserCodes.StudentCode
				const Ref = firebase.firestore().collection("StudentID").where("StudentCode", "==", studentCode)
					.where("courseName", "==", this.studentData.courseName).limit(3)
				Ref.get().then((documents) => {
					this.similarProfileList = []
					documents.forEach((doc) => {
						if (doc.id != this.studentId) {

							var obj = {}
							obj.id = doc.id
							obj.name = doc.data().FullName
							obj.photo = doc.data().profilephoto
							obj.course = doc.data().courseName
							this.similarProfileList.push(obj)
						}
					})
				})
			}
		},
		fetchStudentData() {
			firebase
				.firestore()
				.collection("StudentID")
				.doc(this.studentId)
				.onSnapshot((doc) => {
					// console.log(doc.data());
					this.studentData = doc.data();
					// console.log(this.studentData.AppliedIn)
					this.getExperience;
					this.getEducation;
					this.getAccomp;
				});
		},
		async exportpdf() {
			this.exporting = true;
			// generateResume();
			// console.log(this.$refs);
			await this.$refs.document.generateReport();
			setTimeout(() => {
				this.exporting = false;
			}, 5000);
		},
	},
	created() {
		this.studentId = this.$route.params.studentId;
		this.jobId = this.$route.params.jobId ?? null;
		// console.log(this.jobId)
		// if (this.studentId) {
		// 	this.fetchStudentData();
		// }
		// if (this.jobId) {
		// 	this.fetchSimilarProfile();
		// }
		// else {
		// 	console.log('else condition')
		// 	this.fetchSimilarProfilebyCourse();
		// }
	},
	computed: {
		getAccomp() {
			this.accompData = [];
			const db = firebase
				.firestore()
				.collection("StudentID")
				.doc(this.studentId)
				.collection("OtherDetails")
				.doc(this.studentId);
			db.get()
				.then((doc) => {
					if (doc.exists) {
						this.skills = doc.data().skills ?? [];
					}
				})
				.then(() => {
					db.collection("Certification")
						.get()
						.then((snapshot) => {
							snapshot.docs.forEach((doc) => {
								var obj = {
									color: "primary",
									icon: "bx-trophy",
									title: doc.data().name,
									desc: doc.data().desc,
									time: doc.data().date,
								};
								this.accompData.push(obj);
							});
						});
				});
		},
		getEducation() {
			var obj = {};
			var obj1 = {};
			var obj2 = {};
			this.educationdata = [];
			const db = firebase
				.firestore()
				.collection("StudentID")
				.doc(this.studentId)
				.collection("EducationDetails")
				.doc(this.studentId);
			db.get()
				.then((doc) => {
					if (doc.exists) {
						// console.log(doc.data())
						obj = {
							color: "primary",
							icon: "bx-book-reader",
							title: `${doc.data().courseName} - ${doc.data().branch}`,
							desc: doc.data().CollegeName,
							time:
								typeof doc.data().AdmDate != "string"
									? `${doc
										.data()
										.AdmDate.toDate()
										.getFullYear()} - ${doc.data().grad_date.toDate().getFullYear()}`
									: `${doc.data().AdmDate} - ${doc.data().grad_date}`,
							score: doc.data().current_Percentage,
						};
						// this.educationdata.push(obj);

						if (doc.data().twelve != null) {
							obj1 = {
								color: "primary",
								icon: "bx-book-reader",
								title: "Class 12th",
								desc: doc.data().twelve.school,
								time: doc.data().twelve.end_date,
								score: doc.data().twelve.score,
							};
							// this.educationdata.push(obj1);
						}
						if (doc.data().tenth != null) {
							obj2 = {
								color: "primary",
								icon: "bx-book-reader",
								title: "Class 10th",
								desc: doc.data().tenth.school,
								time: doc.data().tenth.end_date,
								score: doc.data().tenth.score,
							};
							// this.educationdata.push(obj2);
						}
					}
				})
				.then(() => {
					db.collection("PreviousEducationDetails")
						.get()
						.then((snapshot) => {
							if (snapshot.size > 0) {
								this.educationdata.push(obj);

								snapshot.docs.forEach((value) => {
									var obj3 = {
										color: "primary",
										icon: "bx-book-reader",
										title: `${value.data().courseName} - ${value.data().branch}`,
										desc: value.data().CollegeName,
										time: `${value
											.data()
											.AdmDate.toDate()
											.getFullYear()} - ${value.data().grad_date.toDate().getFullYear()}`,
										score: value.data().current_Percentage,
									};
									this.educationdata.push(obj3);
								});
								this.educationdata.push(obj1);
								this.educationdata.push(obj2);
							} else {
								this.educationdata.push(obj);
								this.educationdata.push(obj1);
								this.educationdata.push(obj2);
							}
						})
						.then(() => {
							// console.log("here");
							this.education;
						});
				});
		},
		getExperience() {
			this.experiencedata = [];
			const db = firebase
				.firestore()
				.collection("StudentID")
				.doc(this.studentId)
				.collection("ExperienceDetails");
			db.get().then((snapshot) => {
				snapshot.docs.forEach((doc) => {
					var dateString = new Date(doc.data().start_date).toLocaleDateString("en-GB", {
						year: "2-digit",
						month: "short",
					});
					var dateString2 = new Date(doc.data().end_date).toLocaleDateString("en-GB", {
						year: "2-digit",
						month: "short",
					});
					var obj = {
						color: "primary",
						icon: "bx-briefcase-alt-2",
						title: `${doc.data().JobTitle} - ${doc.data().CompanyName}`,
						jobtitle: `${doc.data().JobTitle}`,
						company: `${doc.data().CompanyName}`,
						desc: doc.data().details,
						time: `${dateString} - ${dateString2}`,
						type: doc.data().positiontype,
					};
					this.experiencedata.push(obj);
				});
			});
		},

	},
	data() {
		return {
			studentId: null,
			studentData: {},
			experiencedata: [],
			educationdata: [],
			accompData: [],
			skills: [],
			jobId: null,
			exporting: false,
			similarProfileList: [],
			perfectScrollbarSettings: {
				maxScrollbarLength: 150,
			}
		};
	},
};
</script>
<style>
.profile-scroller {
	height: calc(100vh - (3.35rem + 4.45rem + 4rem));
}

@media (max-width: 768px) {
	.profile-scroller {
		height: auto
	}
}

@media (max-width: 991px) {
	.profile-scroller {
		height: auto
	}
}
</style>
