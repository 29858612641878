<template>
	<div class="m-2 mt-3 mb-1">
		<h4 class="mb-1" style="font-size: 20px; font-weight:600">
			Skills
		</h4>
		<b-badge 
			v-for="skill in skillsList" :key="skill"
			pill
			variant="light-dark"
			class="text-capitalize font-weight-bold mr-2 mb-1"
		>
			{{ skill }}
		</b-badge>
	</div>
</template>
<script>
import { BBadge } from "bootstrap-vue";

export default {
	components: {
		BBadge,
	},
	props: {
		skillsList: {
			type: Array,
		},
	},
};
</script>
