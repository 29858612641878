<template>
  <div class="mt-2 mb-2" style="padding-right: 2rem;">
    <h4 class="mb-1" style="font-size: 20px; font-weight:600;">
      {{ label }}
    </h4>
  <app-timeline>
    <app-timeline-item v-for="(item,index) in dataArray" :key="index"
      :title="item.title"
      :subtitle="item.desc"
      icon="fi-rr-check"
      :time="item.score"
      :variant="item.color"
      :extraQuote="item.time" 
    />
  </app-timeline>
    </div>

</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props:{
    dataArray:{
      type:Array
    },
    label:{
      type:String
    }
  }
}
</script>
