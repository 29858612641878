<template>
	<b-media class="mb-2 px-4" right-align>
		<b-media-body       >
			<h5 class=" font-weight-bold">
				{{ item.title }}
			</h5>
			<h6 class="mt-25" style="font-size: 20px; font-weight:600">
				{{ item.desc }}
			</h6>
			<span class="mt-25 " style="font-size: 16px;font-weight:600">{{
				item.time
			}}</span>
		</b-media-body>
		<template #aside class="m-1">
			<span class="font-weight-bold "> {{ item.score }}</span>
		</template>
	</b-media>
</template>
<script>
import { BRow, BCol, BMedia, BMediaAside, BMediaBody } from "bootstrap-vue";

export default {
	components: {
		BRow,
		BCol,
		BMediaBody,
		BMediaAside,
		BMedia,
	},
	props: {
		item: {
			type: Object,
		},
	},
};
</script>
