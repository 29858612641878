<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <span class="flex flex-wrap">
      <p>COPYRIGHT © {{ new Date().getFullYear() }} </p>
      <a
        href="https://www.flairboat.com"
        target="_blank"
        rel="nofollow"
        class="font-semibold ml-1"
        >Flairboat Pvt. Ltd.</a
      >
      <p class="">, All rights Reserved</p>
    </span>
    <span class="md:flex hidden items-center">
      <span> Made with</span>
      <i class="bx bxs-heart mx-2 text-danger"></i>
      <!-- <feather-icon
        icon="HeartIcon"
        svgClasses="stroke-current text-danger mr-1 w-6 h-6"
        class="ml-2"
      /> -->
      <span> in 🇮🇳 </span>
    </span>
  </footer>
</template>

<script>
  export default {
    name: "the-footer",
    props: {
      classes: {
        type: String,
      },
    },
  };
</script>
<style lang="scss" scoped>
  .the-footer {
    position: fixed;
    bottom: 0;
    left: 240px;
    width: calc(95vw - 240px);
    // box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, .05);
    // z-index: 40000;
    padding: 1rem 2.2rem;
    @media screen and (max-width: 991px) {
      width: 95vw;
      left: 0;
    }
    @media screen and (max-width: 771px) {
      width: calc(100% - 2.2rem);
      left: 0;
    }
  }
</style>