import axios from 'axios';
import  firebase from 'firebase/app'

export const MeetingApi = axios.create({ baseURL: 'https://api.flairboat.com/meeting'});
var StartResponse = {meetingId: null};

export const startInterview = (name) => {
    return new Promise(async(resolve, reject) => {
        const response = await MeetingApi.post('/start', {name :name, userId: firebase.auth().currentUser.uid})
        console.log(response);
        if(response.data != null){
            resolve(response.data);
        }else reject('Something went wrong');
    })
}

export const joinInterview = (meetingId) => {
    return new Promise(async(resolve, reject) => {
        const response = await MeetingApi.get(`/join?meetingId=${meetingId}`);
        console.log(response);
        if(response.data != null){
            resolve(response.data);
        }else reject('Something went wrong');
    })
}
export const getErrorMessage = (error) => {
    const { response } = error;
    if (error.response) {
        return response.data.message;
    }
    return error.message;
};
export const GRID_MAP = (screensize , totalItems) => {
    if(screensize == 'xs'){
        if(totalItems == 3){
            return [3,1]
        } else if(totalItems == 2){
            return [2,1]
        } else {
            return [1,1]
        }
    } else if(screensize == 'sm'){
        if(totalItems == 3){
            return [2,2]
        } else if(totalItems == 2){
            return [1,2]
        } else if( totalItems == 4){
            return [2,2]
        }else if( totalItems == 5){
            return [2,3]
        }else if( totalItems == 6){
            return [2,3]
        }else if( totalItems == 7){
            return [3,3]
        }else if( totalItems == 8){
            return [3,3]
        }else if( totalItems == 9){
            return [3,3]
        }
        else {
            return [1,1]
        }
    } else if(screensize == 'md' || screensize == 'lg' || screensize == 'xl'){
        if(totalItems == 3){
            return [2,2]
        } else if(totalItems == 2){
            return [1,2]
        } else if( totalItems == 4){
            return [2,2]
        }else if( totalItems == 5){
            return [2,3]
        }else if( totalItems == 6){
            return [2,3]
        }else if( totalItems == 7){
            return [3,3]
        }else if( totalItems == 8){
            return [3,3]
        }else if( totalItems == 9){
            return [3,3]
        } else if(totalItems == 10 || totalItems == 11 ||totalItems == 12 ){
            return [3,4]
        }else if (totalItems == 13 || totalItems == 14 ||totalItems == 15 ||totalItems == 16){
            return [4,4]
        }
        else {
            return [1,1]
        }
    } else{
        return [1,1]
    }
    
    
};

export const VIDEO_PER_PAGE = {
    ['xs']: 3,
    ['sm']: 9,
    ['md']: 12,
    ['lg']: 12,
    ['xl']: 12,
};
